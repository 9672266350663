@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #424242;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input.login {
  @apply inline-block h-[56px] w-[443px] border border-[#757575] rounded-[3px] px-[12px];
}

input {
  @apply inline-block w-[347px] h-[36px] shadow-vixell-dark-more rounded-[3px] px-[12px] text-sm;
}

input.radio {
  @apply border-none shadow-none w-16 h-16;
}

input.checkbox {
  @apply border-none shadow-none w-12 h-12;
}

label {
  @apply text-[12px] text-vixell-gray-dark font-bold;
}

label.alert {
  @apply text-[12px] text-vixell-red font-normal;
}

select {
  @apply inline-block w-[347px] h-[36px] shadow-vixell-dark-more rounded-[3px] px-[12px] text-sm font-bold;
}

textarea {
  @apply inline-block w-[347px] shadow-vixell-dark-more rounded-[3px] px-[12px] text-sm resize-none;
}

.error {
  @apply text-[14px] py-[8px] text-red-500;
}

h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl font-bold;
}
h4 {
  @apply text-sm font-bold;
}

.vixell-gradient {
  background: linear-gradient(
    90deg,
    #00bcd4 0.27%,
    rgba(156, 39, 176, 0.51) 82.8%
  );
}

.container {
  @apply w-[90vw] max-w-[1440px] mx-auto;
}

.medium-container {
  @apply w-[900px];
}

.white-wall-with-padding {
  @apply bg-white p-[32px];
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }
  .print-container {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}
.print-h2 {
  @apply text-[18pt] font-bold underline;
}
.print-text-md {
  @apply text-[12pt];
}
.print-text-lg {
  @apply text-[16pt];
}
.print-text-xl {
  @apply text-[18pt];
}
.print-text-2xl {
  @apply text-[20pt];
}
.print-page {
  page-break-after: always;
  box-sizing: border-box;
  height: 297mm;
  width: 210mm;
  padding: 12mm;
  margin: 0 auto;
  background-color: #fff;
}
